import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

export function getRegistryList() {
  return request({
    url: envInfo.bgApp.registryPath + '/registry/find/list',
    method: 'get',
  })
}

/**
 * 新建注册表
 * @param params
 */
export function createRegistry(params) {
  return request({
    url: envInfo.bgApp.registryPath + '/registry/create',
    method: 'post',
    data: params
  })
}

/**
 * 更新注册表
 * @param params
 */
export function updateRegistry(params) {
  return request({
    url: envInfo.bgApp.registryPath + '/registry/edit',
    method: 'post',
    data: params
  })
}

/**
 * 删除注册表
 * @param params
 */
export function deleteRegistry(params) {
  return request({
    url: envInfo.bgApp.registryPath + `/registry/delete/${params.registryId}/${params.path}/${params.name}`,
    method: 'delete',
  })
}





