<template>
  <div class="app-container-empty">
    <el-row>
      <el-col :style="{ width: 'calc(50% - 5px)' }">
        <el-card class="registryCard">
          <div slot="header" class="cardHeader">
            <el-row>
              <el-col :span="16">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="small"
                  @click="createNode()"
                >
                  {{ $t("registry.addRootProperty") }}
                </el-button>
              </el-col>
              <el-col :span="8">
                <el-input
                  clearable
                  :placeholder="$t('commons.searchPhrase')"
                  maxlength="50"
                  v-model="filterText"
                >
                </el-input>
              </el-col>
            </el-row>
          </div>
          <div>
            <el-tree
              v-loading="loading"
              :data="data"
              node-key="id"
              @node-drag-end="handleDragEnd"
              :highlight-current="true"
              draggable
              :allow-drop="allowDrop"
              :filter-node-method="filterNode"
              :expand-on-click-node="false"
              ref="tree"
            >
              <span
                class="custom-tree-node"
                slot-scope="{ node, data }"
                @mouseenter="mouseoverNode(node)"
                @mouseleave="mouseoutNode(node)"
              >
                <span @click="clickNode(node, data)">{{ node.label }}</span>
                <template v-if="node.data.show">
                  <span>
                    <el-button
                      type="text"
                      :style="{ marginLeft: '20px' }"
                      @click="() => appendNode(node)"
                    >
                      <i class="el-icon-circle-plus-outline"></i>
                    </el-button>
                    <el-button
                      type="text"
                      @click="() => removeNode(node, data)"
                    >
                      <i class="el-icon-delete delete-btn"></i>
                    </el-button>
                  </span>
                </template>
              </span>
            </el-tree>
          </div>
        </el-card>
      </el-col>
      <el-col :style="{ width: 'calc(50% - 5px)', marginLeft: '10px' }">
        <el-card class="registryCard">
          <el-form
            label-position="top"
            :model="registry.form"
            ref="registryForm"
            :rules="registry.formRule"
          >
            <el-form-item prop="registryId" style="display: none">
              <el-input v-model="registry.form.registryId"></el-input>
            </el-form-item>
            <el-form-item :label="$t('registry.name')" prop="name">
              <el-input
                v-model="registry.form.name"
                maxlength="50"
                :show-word-limit="true"
                autocomplete="off"
                :readonly="this.readonly"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('registry.displayName')"
              prop="displayName"
            >
              <el-input
                v-model="registry.form.displayName"
                maxlength="65"
                :show-word-limit="true"
                autocomplete="off"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
            <el-form-item prop="parentId" style="display: none">
              <el-input v-model="registry.form.parentId"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('registry.parentLevel')"
              prop="parentText"
              style="display: none"
            >
              <el-input
                v-model="registry.form.parentText"
                readonly="readonly"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('registry.value')" prop="value">
              <el-input
                v-model="registry.form.value"
                maxlength="500"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('registry.parentPath')" prop="parentPath">
              <el-input
                v-model="registry.form.parentPath"
                maxlength="33"
                :show-word-limit="true"
                readonly="readonly"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('registry.description')"
              prop="description"
            >
              <el-input
                v-model="registry.form.description"
                maxlength="160"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('commons.unActive')" prop="status">
              <el-select v-model="registry.form.status" style="width: 100%">
                <el-option
                  v-for="(item, key) in statusOptions"
                  :key="key"
                  :label="item"
                  :value="key"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="saveRegistry()">
                {{ $t("commons.save") }}
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getRegistryList,
  createRegistry,
  updateRegistry,
  deleteRegistry,
} from "@/api/ruge/registry/registry";
import { listToTree } from "@/utils/tree";

export default {
  name: "Registry",
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  data() {
    return {
      loading: true,
      filterText: "",
      data: [],
      statusOptions: {
        1: this.$t("commons.active"),
        0: this.$t("commons.unActive"),
      },
      treeRootId: 0,
      readonly: false,
      defaultProps: {
        children: "children",
        label: "label",
        show: "show",
      },
      registry: {
        show: false,
        label: null,
        children: null,
        id: null,
        form: {
          registryId: null,
          name: null,
          displayName: null,
          parentId: this.treeRootId,
          parentText: this.$t("commons.empty"),
          value: null,
          parentPath: null,
          status: "1",
          description: null,
        },
        formRule: {
          name: [
            {
              required: true,
              trigger: ["blur", "change"],
              pattern: /^[A-Za-z0-9-@#()]+$/,
              message: this.$t("commons.stringRule"),
            },
          ],
          displayName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          status: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
    };
  },
  created() {
    this.getRegistryList();
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.toUpperCase().indexOf(value.toUpperCase()) !== -1;
    },
    getRegistryList() {
      this.loading = true;
      getRegistryList()
        .then((response) => {
          let data = listToTree(response, "registryId", "parentId");
          this.recursiveAttr(data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //递归修改属性及数据结构
    recursiveAttr(row) {
      let data = row.map((v) => {
        v.label = v.displayName;
        v.show = false;
        v.id = v.registryId;
        this.$set(v, "status", v.status + "");
        if (v.children != undefined) {
          this.recursiveAttr(v.children);
        }
        return v;
      });
      this.data = data;
    },
    allowDrop(node) {
      return node.isLeaf;
    },
    handleDragEnd(draggingNode, dropNode, dropType) {
      if (dropType == "before") {
        draggingNode.data.parentId = dropNode.data.parentId;
        draggingNode.data.parentPath = dropNode.data.parentPath;
      } else if (dropType == "after") {
        draggingNode.data.parentId = dropNode.data.parentId;
        draggingNode.data.parentPath = dropNode.data.parentPath;
      } else if (dropType == "inner") {
        draggingNode.data.parentId = dropNode.data.registryId;
        draggingNode.data.parentPath =
          dropNode.data.parentPath + "." + dropNode.data.name;
      } else if (dropType == "none") {
        return;
      }
      updateRegistry(draggingNode.data)
        .then(() => {
          // this.getRegistryList();
          // 成功提示
          this.$message({
            type: "success",
            message: this.$t("message.saveSuccess"),
          });
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    appendNode(node) {
      this.clearForm();
      this.readonly = false;
      this.registry.form.parentId = node.data.registryId;
      this.registry.form.parentText = node.data.displayName;
      if (node.data.parentPath) {
        this.registry.form.parentPath =
          node.data.parentPath + "." + node.data.name;
      } else {
        this.registry.form.parentPath = node.data.name;
      }
    },
    createNode() {
      this.clearForm();
      this.readonly = false;
    },
    saveRegistry() {
      this.$refs.registryForm.validate((valid) => {
        if (valid) {
          if (this.registry.form.registryId != null) {
            updateRegistry(this.registry.form)
              .then(() => {
                this.updateTreeNode();
                //this.getRegistryList()
                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("message.saveSuccess"),
                });
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            createRegistry(this.registry.form)
              .then((node) => {
                //this.getRegistryList()
                this.createSonNode(node);

                if (node.parentId == this.treeRootId) {
                  this.data.push(node);
                }

                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("message.saveSuccess"),
                });
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    removeNode(node, data) {
      if (node.isLeaf) {
        this.$confirm(
          this.$t("message.deleteConfirm"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            deleteRegistry({
              registryId: data.registryId,
              path: data.parentPath,
              name: data.name,
            })
              .then(() => {
                this.clearForm();
                // 成功提示
                this.$message({
                  type: "success",
                  message: this.$t("message.deleteSuccess"),
                });
                //异步更新
                const parent = node.parent;
                const children = parent.data.children || parent.data;
                const index = children.findIndex((d) => d.id === data.id);
                children.splice(index, 1);

                // 刷新菜单
                //this.getRegistryList()
              })
              .catch((error) => {
                console.log(`未删除，原因 => ${error}`);
              });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      } else {
        // 失败提示
        this.$message({
          type: "info",
          message: "有子节点，不可删除",
        });
      }
    },
    mouseoverNode(node) {
      node.data.show = true;
    },
    mouseoutNode(node) {
      node.data.show = false;
    },
    clickNode(node, data) {
      this.readonly = true;
      this.registry.form.registryId = data.registryId;
      this.registry.form.name = data.name;
      this.registry.form.displayName = data.displayName;
      this.registry.form.parentId = data.parentId;
      if (this.registry.form.parentId == this.treeRootId) {
        this.registry.form.parentText = this.$t("commons.empty");
      } else {
        this.registry.form.parentText = node.parent.data.displayName;
      }
      this.registry.form.value = data.value;
      this.registry.form.parentPath = data.parentPath;
      this.registry.form.status = data.status;
      this.registry.form.description = data.description;
    },
    clearForm() {
      this.registry.form.registryId = null;
      this.registry.form.name = null;
      this.registry.form.displayName = null;
      this.registry.form.parentId = this.treeRootId;
      this.registry.form.parentText = this.$t("commons.empty");
      this.registry.form.value = null;
      this.registry.form.parentPath = null;
      this.registry.form.status = "1";
      this.registry.form.description = null;
    },
    //节点数据更新
    updateTreeNode() {
      this.$refs.tree.getCurrentNode().registryId =
        this.registry.form.registryId;
      this.$refs.tree.getCurrentNode().name = this.registry.form.name;
      this.$refs.tree.getCurrentNode().displayName =
        this.registry.form.displayName;
      this.$refs.tree.getCurrentNode().value = this.registry.form.value;
      this.$refs.tree.getCurrentNode().parentPath =
        this.registry.form.parentPath;
      this.$refs.tree.getCurrentNode().status = this.registry.form.status;
      this.$refs.tree.getCurrentNode().description =
        this.registry.form.description;
      this.$refs.tree.getCurrentNode().parentText =
        this.registry.form.parentText;
      this.$refs.tree.getCurrentNode().parentId = this.registry.form.parentId;
      this.$refs.tree.getCurrentNode().label = this.registry.form.displayName;
    },
    createSonNode(node) {
      node.id = node.registryId;
      node.show = false;
      node.children = null;
      node.label = node.displayName;
      if (node.parentId == this.treeRootId) {
        node.parentText = this.$t("commons.empty");
        node.children = [];
      } else {
        this.$refs.tree.store.nodesMap[
          this.$refs.tree.getCurrentNode().id
        ].expanded = true;
        node.parentText = this.$refs.tree.getCurrentNode().displayName;
        if (this.$refs.tree.getCurrentNode().children == null) {
          this.$refs.tree.getCurrentNode().children = [];
        }
        this.$refs.tree.getCurrentNode().children.push(node);
      }
    },
  },
};
</script>

<style scoped>
.registryCard {
  height: calc(100vh - 90px);
}

.registryCard >>> .el-card__header {
  padding: 6px 10px;
}

.registryCard >>> .el-card__body {
  overflow-y: auto;
  height: calc(100%);
}

.registryCard >>> .el-tree {
  margin-bottom: 50px;
}

.cardHeader {
  line-height: 34px;
  font-weight: 600;
}
</style>
